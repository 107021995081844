import React, { useState } from 'react';
import { Modal, Button, TextField, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';

const IncomeModal = ({ open, handleClose, incomeDetails, handleSave }) => {
  const [incomeData, setIncomeData] = useState(incomeDetails || {
    description: '',
    amount: '',
    dateReceived: '',
    source: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIncomeData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(incomeData);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20, width: 400 }}>
        <Button onClick={() => console.log("Edit operation")} startIcon={<EditIcon />}>Edit</Button>
        <Button onClick={() => console.log("List operation")} startIcon={<ListIcon />}>List</Button>
        <Button onClick={() => console.log("Delete operation")} startIcon={<DeleteIcon />}>Delete</Button>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={incomeData.description}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Amount"
            name="amount"
            type="number"
            value={incomeData.amount}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Date Received"
            name="dateReceived"
            type="date"
            value={incomeData.dateReceived}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Source</InputLabel>
            <Select
              name="source"
              value={incomeData.source}
              onChange={handleChange}
            >
              <MenuItem value="BrickAbode">Value 1</MenuItem>
              <MenuItem value="Cliqx">Value 1</MenuItem>
              <MenuItem value="Geobyte">Value 1</MenuItem>
              <MenuItem value="Viasat">Value 1</MenuItem>
              <MenuItem value="Bolsa Doc">Value 1</MenuItem>
              <MenuItem value="Bolsa Doc II">Value 1</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" color="primary" variant="contained" fullWidth>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default IncomeModal;
