import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

const FinancialOverviewCard = () => {
  // Example static data
  const financialData = {
    highestSpendingMonth: { month: 'August', amount: '$4,500' },
    topSpendingCategories: [
      { category: 'Entertainment', amount: '$2,000' },
      { category: 'Travel', amount: '$1,500' }
    ],
    highestSavingMonth: { month: 'January', amount: '$3,000' }
  };

  return (
    <Card sx={{ flex: 1, margin: 1, textAlign: 'center', p: 2, boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>Financial Overview of the year</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography sx={{ mb: 1 }}>The month you most spent money was: {financialData.highestSpendingMonth.month} - {financialData.highestSpendingMonth.amount}</Typography>
        <Typography sx={{ mb: 1 }}>The first category most spent was: {financialData.topSpendingCategories[0].category} - {financialData.topSpendingCategories[0].amount}</Typography>
        <Typography sx={{ mb: 1 }}>The second category most spent was: {financialData.topSpendingCategories[1].category} - {financialData.topSpendingCategories[1].amount}</Typography>
        <Typography>The month you saved the most money was: {financialData.highestSavingMonth.month} - {financialData.highestSavingMonth.amount}</Typography>
      </CardContent>
    </Card>
  );
};

export default FinancialOverviewCard;

