import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Typography, Paper } from '@mui/material';
const WorldClockModal = () => {
  const [times, setTimes] = useState({});

  const updateTime = () => {
    setTimes({
      newYork: moment().tz("America/New_York").format("h:mm:ss A"),
      london: moment().tz("Europe/London").format("h:mm:ss A"),
      tokyo: moment().tz("Asia/Tokyo").format("h:mm:ss A"),
      brasilia: moment().tz("America/Sao_Paulo").format("h:mm:ss A"),
      madrid: moment().tz("Europe/Madrid").format("h:mm:ss A"),
    });
  };

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Typography variant="body1" component="div">New York: {times.newYork}</Typography>
      <Typography variant="body1" component="div">London: {times.london}</Typography>
      <Typography variant="body1" component="div">Tokyo: {times.tokyo}</Typography>
      <Typography variant="body1" component="div">Brasilia: {times.brasilia}</Typography>
      <Typography variant="body1" component="div">Madrid: {times.madrid}</Typography>
    </div>
  );
};

export default WorldClockModal;
