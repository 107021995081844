
export const handleAddGoalSubmit = async (goalData, setShowGoalsModal) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://money-couple.com/api/goal/", {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(goalData),
      });
  
      if (response.ok) {
        console.log("Goal added successfully");
        setShowGoalsModal(false);
      } else {
        const errorData = await response.json();
        console.error("Failed to add goal:", errorData);
      }
    } catch (error) {
      console.error("Error adding goal:", error);
    }
};

export const handleListGoals = async (setGoalsData, handleErrors) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://money-couple.com/api/goal/list/", {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Goals retrieved successfully");
        setGoalsData(data);
      } else {
        const errorData = await response.json();
        console.error("Failed to retrieve goals:", errorData);
        if (handleErrors) {
          handleErrors(errorData);
        }
      }
    } catch (error) {
      console.error("Error retrieving goals:", error);
      if (handleErrors) {
        handleErrors(error);
      }
    }
};

export default {
  handleAddGoalSubmit,
  handleListGoals
};
