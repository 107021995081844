import React from 'react';
import { Card, CardContent, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';

const RecentActivityCard = () => {
  // Example data for recent activities
  const activities = [
    { id: 1, desc: "Paid rent", amount: "$1500" },
    { id: 2, desc: "Salary received", amount: "$4000" },
    { id: 3, desc: "Utilities payment", amount: "$300" },
    { id: 4, desc: "Grocery shopping", amount: "$250" },
    { id: 5, desc: "Car insurance", amount: "$1200" }
  ];

  return (
    <Card sx={{
      flex: 1,
      margin: 1,
      textAlign: 'center',
      p: 2,
      boxShadow: 3,
      borderRadius: 2
    }}>
      <CardContent>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>Recent Activity</Typography>
        <Divider sx={{ my: 2 }} />
        <List sx={{ width: '100%' }}>
          {activities.map((activity) => (
            <ListItem key={activity.id} sx={{ justifyContent: 'center', textAlign: 'center' }}>
              <ListItemText primary={`${activity.desc}: ${activity.amount}`} primaryTypographyProps={{ textAlign: 'center' }} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default RecentActivityCard;
