import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function HelpModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="help-dialog-title">
      <DialogTitle id="help-dialog-title">
        Help
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          For any problems or bugs please contact wcezar@chesscoding.tech
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default HelpModal;
