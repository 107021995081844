import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container, Typography, TextField, Button, Dialog,
  DialogContent, DialogTitle, IconButton, InputAdornment,
  FormControl, InputLabel, OutlinedInput, DialogActions,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    // navigate("/dashboard");
    // API request to the backend
    try {
      const response = await fetch('https://money-couple.com/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);  // Save the token to localStorage
        navigate("/dashboard");  // Navigate to the dashboard
      } else {
        const data = await response.json();
        setError(data.error || 'Invalid Credentials');  // Show error message from backend or a default one
      }
    } catch (err) {
      console.error("Login Error:", err);
      setError('Failed to login. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // Registration state
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [registerError, setRegisterError] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log("Register attempt");
    setShowRegisterDialog(false);
  };


  return (
    <Container maxWidth="xs">
      <Typography variant="h4" sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
        Money Couple
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel htmlFor="login-password">Password</InputLabel>
          <OutlinedInput
            id="login-password"
            type={isPasswordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Login
        </Button>
        <Button color="primary" onClick={() => setShowRegisterDialog(true)} fullWidth sx={{ mt: 1 }}>
          Create an account
        </Button>
        <Button color="primary" fullWidth sx={{ mt: 1 }}>
          Forgot Your Password?
        </Button>
      </form>
      <Dialog open={showRegisterDialog} onClose={() => setShowRegisterDialog(false)}>
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          <form onSubmit={handleRegister}>
            <TextField
              margin="dense"
              label="Full Name"
              type="text"
              fullWidth
              variant="outlined"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              variant="outlined"
              value={registerUsername}
              onChange={(e) => setRegisterUsername(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel htmlFor="register-password">Password</InputLabel>
              <OutlinedInput
                id="register-password"
                type={isPasswordVisible ? "text" : "password"}
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="confirm-password"
                type={isConfirmPasswordVisible ? "text" : "password"}
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)} edge="end">
                      {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {registerError && <Typography color="error" sx={{ mt: 2 }}>{registerError}</Typography>}
            <DialogActions>
              <Button onClick={() => setShowRegisterDialog(false)}>Cancel</Button>
              <Button type="submit" color="primary">Register</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default Login;
