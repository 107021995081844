import React, { useState } from 'react';
import { Modal, Button, TextField, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import DeleteIcon from '@mui/icons-material/Delete';

const GoalsModal = ({ open, handleClose, goalDetails, handleSave, handleList, handleEdit, handleDelete }) => {
  const [goalData, setGoalData] = useState(goalDetails || {
    name: '',
    description: '',
    current_value: '0',
    goal_value: '',
    status: 'in_progress'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave(goalData);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 4, width: 400, boxShadow: 24 }}>
        <Button onClick={() => console.log("Edit operation")} startIcon={<EditIcon />}>Edit</Button>
        <Button onClick={() => console.log("List operation")} startIcon={<ListIcon />}>List</Button>
        <Button onClick={() => console.log("Delete operation")} startIcon={<DeleteIcon />}>Delete</Button>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Goal Name"
            name="name"
            value={goalData.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={goalData.description}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Current Value"
            name="current_value"
            type="number"
            value={goalData.current_value}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Goal Value"
            name="goal_value"
            type="number"
            value={goalData.goal_value}
            onChange={handleChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={goalData.status}
              onChange={handleChange}
            >
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="stopped">Stopped</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" color="primary" variant="contained" fullWidth>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default GoalsModal;
