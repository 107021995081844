import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Login from "./Login";
import Dashboard from "./Dashboard";
import CssBaseline from '@mui/material/CssBaseline'; // Helps with CSS baseline reset using Material-UI
import { lightTheme, darkTheme } from './themes/themes';

function App() {
  const [theme, setTheme] = useState('light');  // State to toggle theme

  const appliedTheme = theme === 'light' ? lightTheme : darkTheme;

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard toggleTheme={toggleTheme} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
